<script>
import appConfig from "@/app.config";

import {mapActions, mapGetters} from "vuex";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum
} from "vuelidate/lib/validators";
import { VclCode } from 'vue-content-loading';
import {AccountAuthProvider} from "@/enums";
import {get_acsrf_token, with_acrf_token} from "@/methods";
import Multiselect from "vue-multiselect";


export default {
  components: {
    PasswordSetup: () => import("@/components/lazy/account/PasswordSetup"),
    PasswordChange: () => import("@/components/lazy/account/PasswordChange"),
    PasswordDisable: () => import("@/components/lazy/account/PasswordDisable"),
    UserPersona: () => import("@/components/lazy/account/UserPersona"),
    EMailChange: () => import("@/components/lazy/account/EMailChange"),
    VclCode,
    Multiselect
  },
  computed: {
    ...mapGetters([
      'getAccountId',
      'getAccountName',
      'getPersonaName',
      'getPersonaAvatar',
      'getUILanguage'
    ]),
    ...mapActions([
        'setPersonaName',
        'setPersonaAvatar',
        'setUILanguage',
    ])
  },
  data() {
    return {
      avatar_url: process.env.VUE_APP_ROOT_API + 'v1/@me/avatar',
      AccountAuthProvider: AccountAuthProvider,
      ready: false,
      error: false,
      account: {},
      twitch_add_url: process.env.VUE_APP_OLYMP + '/v1/twitch/oauth',
      twitch_rem_url: process.env.VUE_APP_OLYMP + '/v1/twitch/remove',
      discord_add_url: process.env.VUE_APP_OLYMP + '/v1/discord/oauth',
      discord_rem_url: process.env.VUE_APP_OLYMP + '/v1/discord/remove',
    };
  },
  created() {
    this.getData()
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/account', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.account = data.account;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    onPasswordEnabled() {
      this.account.authentication.provider = AccountAuthProvider.NATIVE;
    },
    onPasswordDisabled() {
      this.account.authentication.provider = AccountAuthProvider.STEAM;
    },
    onEMailChanged(address) {
      this.account.email.address = address;
      this.$swal({
        icon: 'info',
        text: this.$t('account.email.change.confirmed')
      });
    },
    removeDiscord() {
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/remove-discord', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
          .then(response => {
            if(response.ok || response.status === 400){
              return response.json();
            } else {
              if(response.status === 429) {
                this.$swal({
                  icon: 'warning',
                  text: this.$t('error.server.ratelimit.message'),
                  title: this.$t('error.server.ratelimit.title')
                });
              } else
                throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {
            if(!data) return;
            if(data.status) {
              this.account.connections.discord = null;
            } else {
              this.$swal({
                icon: 'error',
                text: this.$t('account.connections.error')
              });
            }
          })
          .catch(error => {
            console.log(`[ERROR] ${error}`);
            this.$swal({
              icon: 'error',
              text: this.$t('account.connections.error')
            });
          });
      }).catch(error => {
        console.log('[ERROR] Failed to request acrf token');
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      });
    },
    removeTwitch() {
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/remove-twitch', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
          .then(response => {
            if(response.ok || response.status === 400){
              return response.json();
            } else {
              if(response.status === 429) {
                this.$swal({
                  icon: 'warning',
                  text: this.$t('error.server.ratelimit.message'),
                  title: this.$t('error.server.ratelimit.title')
                });
              } else
                throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {
            if(!data) return;
            if(data.status) {
              this.account.connections.twitch = null;
            } else {
              this.$swal({
                icon: 'error',
                text: this.$t('account.connections.error')
              });
            }
          })
          .catch(error => {
            console.log(`[ERROR] ${error}`);
            this.$swal({
              icon: 'error',
              text: this.$t('account.connections.error')
            });
          });
      }).catch(error => {
        console.log('[ERROR] Failed to request acrf token');
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      });
    }
  }
};
</script>
<template>
  <div>
    <div v-if="ready">
      <div class="row d-flex">
        <!-- Persona -->
        <div class="col-xl-4 d-flex">
          <div class="card flex-fill">
            <div class="card-body">
              <h4 class="card-title">
                {{ $t('account.persona.title') }}
                <small class="text-muted">
                  {{ $t('account.persona.description') }}
                </small>
              </h4>
              <hr>
              <UserPersona></UserPersona>
            </div>
          </div>
        </div>
        <!-- General -->
        <div class="col-xl-4 d-flex">
          <div class="card flex-fill">
            <div class="card-body">
              <h4 class="card-title">{{ $t('account.overview.title') }}</h4>
              <hr>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="cf-id">{{ $t('account.overview.cftools_id') }}</label>
                    <input
                        id="cf-id"
                        type="text"
                        class="form-control"
                        placeholder="First name"
                        :value="account.cftools_id"
                        disabled="disabled"
                        style="font-family: 'Courier New'"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="cf-username">{{ $t('account.overview.username') }}</label>
                    <input
                        id="cf-username"
                        type="text"
                        class="form-control"
                        :value="getAccountName()"
                        disabled="disabled"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="cf-email">{{ $t('account.overview.email') }}</label>
                    <div class="input-group mb-3">

                      <input
                          id="cf-email"
                          type="text"
                          class="form-control"
                          placeholder="First name"
                          :value="account.email.address"
                          disabled="disabled"
                      />
                      <div class="input-group-append">
                        <EMailChange @addressChanged="onEMailChanged"></EMailChange>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Security -->
        <div class="col-xl-4 d-flex">
          <div class="card flex-fill">
            <div class="card-body">
              <h4 class="card-title">{{ $t('account.security.title') }}</h4>
              <hr>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="cf-email">
                      {{ $t('account.security.provider.label') }}
                      <small class="text-muted">({{ $t('account.security.provider.description') }})</small>
                    </label>
                    <div v-if="account.authentication.provider === AccountAuthProvider.NATIVE">
                      <h5>
                        <i class="fad fa-key-skeleton"></i> {{ $t('account.security.provider.native.label') }}
                        <PasswordChange></PasswordChange>
                        <b class="ml-1 mr-1">{{$t('terms.or')}}</b>
                        <PasswordDisable @passwordDisabled="onPasswordDisabled"></PasswordDisable>
                      </h5>
                    </div>
                    <div v-else-if="account.authentication.provider === AccountAuthProvider.STEAM">
                      <h5>
                        <i class="fab fa-steam"></i> {{ $t('account.security.provider.steam.label') }}
                      </h5>
                      <PasswordSetup @passwordEnabled="onPasswordEnabled"></PasswordSetup>
                    </div>
                    <div v-else>
                      <h5 class="text-danger">{{ $t('account.security.provider.unknown') }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex">
        <!-- Account links -->
        <div class="col-xl-4 d-flex">
          <div class="card flex-fill">
            <div class="card-body">
              <h4 class="card-title">
                {{ $t('account.links.title') }}
                <small class="text-muted">
                  {{ $t('account.links.description') }}
                </small>
              </h4>
              <hr>
              <div class="row">
                <div class="col-sm-2 m-auto justify-content-center text-center">
                    <i class="fab fa-steam text-white" style="font-size: 32px;"></i>
                </div>
                <div class="col-sm m-auto">
                    <a :href="'https://steamcommunity.com/profiles/' + account.links.steam.steam64" target="_blank">
                      {{ account.links.steam.steam64 }} <small><i class="fas fa-external-link-square"></i></small>
                    </a>
                </div>
              </div>
              <div class="row align-middle pt-1">
                <div class="col-sm-2 m-auto justify-content-center text-center">
                  <img src="be_32x32.jpg">
                </div>
                <div class="col-sm m-auto text-white">
                  {{ account.links.battleye.guid }}
                </div>
              </div>
              <div class="row align-middle pt-1">
                <div class="col-sm-2 m-auto justify-content-center text-center">
                  <img src="bi_32x32.jpg">
                </div>
                <div class="col-sm m-auto text-white">
                  {{ account.links.bohemiainteractive.uid }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Connections -->
        <div class="col-xl-4 d-flex">
          <div class="card flex-fill">
            <div class="card-body">
              <h4 class="card-title">
                {{ $t('account.connections.title') }}
                <small class="text-muted">
                  {{ $t('account.connections.description') }}
                </small>
              </h4>
              <hr>
              <div class="row">
                <div class="col-sm-2 m-auto justify-content-center text-center">
                  <i class="fab fa-discord text-white" style="font-size: 32px;"></i>
                </div>
                <div class="col-sm m-auto text-white" v-if="account.connections.discord">
                  {{ account.connections.discord.id }}
                  <small class="text-muted pl-1">
                    {{ $t('account.connections.date') }}
                    {{ $d(parseDate(account.connections.discord.created_at), 'datetime') }}
                  </small>
                  <a v-on:click="removeDiscord" href="#" class="float-right">
                    <i class="fad fa-unlink"></i> {{ $t('account.connections.remove') }}
                  </a>
                </div>
                <div class="col-sm m-auto text-white" v-else>
                  <a :href="discord_add_url" class="float-left">
                    <i class="fad fa-link"></i> {{ $t('account.connections.connect') }}
                  </a>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-sm-2 m-auto justify-content-center text-center">
                  <i class="fab fa-twitch text-white" style="font-size: 32px;"></i>
                </div>
                <div class="col-sm m-auto text-white" v-if="account.connections.twitch">
                  {{ account.connections.twitch.id }}
                  <small class="text-muted pl-1">
                    {{ $t('account.connections.date') }}
                    {{ $d(parseDate(account.connections.twitch.created_at), 'datetime') }}
                  </small>
                  <a v-on:click="removeTwitch" href="#" class="float-right">
                    <i class="fad fa-unlink"></i> {{ $t('account.connections.remove') }}
                  </a>
                </div>
                <div class="col-sm m-auto text-white" v-else>
                  <a :href="twitch_add_url" class="float-left">
                    <i class="fad fa-link"></i> {{ $t('account.connections.connect') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Language -->
        <div class="col-xl-4 d-flex">
          <div class="card flex-fill">
            <div class="card-body">
              <h4 class="card-title">
                {{ $t('account.language.title') }}
                <small class="text-muted">
                  {{ $t('account.language.description') }}
                </small>
              </h4>
              <hr>
              <div class="row">
                <div class="col">
                  <multiselect
                      v-model="language"
                      label="text"
                      track-by="key"
                      :options="languageSelection"
                      :show-labels="false"
                      :allow-empty="false"
                      @input="changeUILanguage()"
                  >
                    <template
                        slot="singleLabel"
                        slot-scope="props"
                    >
                      <div>
                        <flag
                            class="option__image"
                            :iso="props.option.flag"
                            :squared="false"
                        />
                        <span class="option__desc d-inline ml-2">
                          <span class="option__title align-middle h4">
                            {{ props.option.text }}
                          </span>
                        </span>
                      </div>
                    </template>
                    <template
                        slot="option"
                        slot-scope="props"
                    >
                      <div>
                        <flag
                            class="option__image d-inline-block"
                            :iso="props.option.flag"
                            :squared="false"
                        />
                        <div class="option__desc d-inline ml-2">
                          <span class="option__title align-middle h4">
                            {{ props.option.text }}
                          </span>
                        </div>
                      </div>
                    </template>
                  </multiselect>
                  <h5 class="text-uppercase text-muted text-center mt-2">
                    {{ $t('account.language.disclaimer') }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SERVER ERROR -->
    <div v-else-if="error">
      <div class="row">
        <div class="col-lg">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{ $t('account.error.overview.title') }}
              </h5>
            </div>
            <div class="card-body">
              <h5 class="card-title mt-0"> {{ $t('account.error.overview.description') }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- LOADING ANIMATION -->
    <div class="row" v-else>
      <div class="col-lg-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="media">
                  <div class="media-body align-self-center">
                    <vcl-code :height="90"></vcl-code>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>